<script>
import api from "@/helpers/api-rest/api.js";
import { methods } from '@/methods/methods-essay';
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import Multiselect from "vue-multiselect";
import store from '@/state/store';
import Swal from "sweetalert2";
import qs from 'qs';
import { required, numeric, helpers, email } from "vuelidate/lib/validators";
import { errorMessages } from "@/helpers/validations/errorMessages";

// const decimalRegex = helpers.regex('decimal', /^-?\d+(\.\d+)?$/);
// Validador: Detecta si contiene comas o apóstrofes (separadores incorrectos)
const noCommasOrApostrophes = helpers.regex("noCommasOrApostrophes", /^[^,']*$/ );

// Validador: Solo caracteres numéricos y puntos
const noInvalidCharacters = helpers.regex("noInvalidCharacters", /^[0-9.]*$/);

// Validador: Número entero o decimal con punto
const validDecimal = helpers.regex("validDecimal", /^-?\d*(\.\d+)?$/);

// const noSpecialChars = helpers.regex("noSpecialChars", /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]*$/);
// const noSpaces = helpers.regex("noSpaces", /^\S*$/);
const onlyLetters = helpers.regex("onlyLetters", /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/);

const validDateFormat = helpers.withParams(
  { type: "validDateFormat" },
  value => {
    if (!value) return false; // Permitir vacío
    const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    return regex.test(value);
  }
);

const validEndDate = helpers.withParams(
  { type: "validEndDate" },
  (value, parentVm) => {
    // `parentVm` es el contexto del elemento actual (action)
    if (!value || !parentVm.init_date) return true; // Permitir vacío o si no hay `init_date`
    const startDate = new Date(parentVm.init_date);
    const endDate = new Date(value);
    return endDate >= startDate; // `end_date` debe ser mayor o igual que `init_date`
  }
);

const currentDate = new Date().toISOString().slice(0, 10);

export default {
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    Multiselect
  },
  props: {
    id: { type: String, default: "ModalEssay" },

    essay: {
      type: Object,
      default: () => {
        return {
          id: null,
          name: "",
          description: "",
          antecedentes: "",
          arte_status: "",
          course_ids: [],
          courses: [],
          strategic_lines: [],
          essay_coordinator: {
            center_id: null,
            essay_professorates: [],
            document_name: ''
          },
          targets: [
            {
              title: "",
              description: "",
              target_results: [{
                id: '',
                target_id: '',
                title: '',
                description: '',
              }]
              // target_achievements: []
            }
          ],
          actions: [
            {
              id: '',
              name: '',
              descriptions: '',
              init_date: currentDate,
              end_date: currentDate,
              responsables: '',
              risks: '',
              contingency_plan: '',
              education_activities: [],
              economic_forecats: []
            }
          ],
          essay_center_partner: [
            {
              essay_professorates: [],
              courses: []
            }
          ]
        };
      },
    },
    isActive: Boolean,
    currentStep: { type: Number, default: 0 },
    project_id: null
  },
  data() {
    return {
      file: null,
      fileName: '',
      filePartnerCompanyname: '',
      selectOptionsCenterType: [],
      modalStatus: this.isActive,
      tableFields: [
        { key: 'description', sortable: false, label: 'Descripción' },
        { key: 'createdBy', sortable: false, label: 'Usuario' },
      ],
      isClosing: false,
      targets: [],
      targetResults: [],
      isCollapseOpen: false,
      collapseOpen: [],
      selectOptionsCenterCoordinators: [],
      selectOptionsCenterPartners: [],
      selectOptionsStrategicLines: [],
      selectedCourses: [],
      selectedCoursesCenterPartners: [],
      selectedCoordinator: '',
      project: this.project_id,
      finished: false,
      loading: false,
      intervalId: null,
      ending: false,
      // errorMessages: [],
      progress: 0,
      interval: null,
      errorMessages,
      draftAction: false,
      coursesByCenter: {}
    };
  },
  computed: {
    userName(){
      return store.getters['auth/nameUser'];
    },
    creationDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
      const year = today.getFullYear();
      return `${day}/${month}/${year}`;
    },
    modalTitle() {
      return this.essay?.id ? "Editar redacción" : "Añadir redacción";
    },
    submitButtonText() {
      return this.essay?.id ? "Save" : "Add Center";
    },
    fileInputLabel() {
      return this.fileName ? this.fileName : (this.essay.essay_coordinator.document_name ? this.essay.essay_coordinator.document_name : "Selecciona archivo...");
    },
  },
  created() {
    this.loadSelectOptionsCenterCoordinators();
  },
  methods: {
    addRowProfessorate: methods.addRowProfessorate,
    deleteRowProfessorate: methods.deleteRowProfessorate,
    addRowPartner: methods.addRowPartner,
    addRowResults: methods.addRowResults,
    addRowAction: methods.addRowAction,
    addRowCourse: methods.addRowCourse,
    addRowCoursesPartner: methods.addRowCoursesPartner,
    deleteRowCourseCenterPartner: methods.deleteRowCourseCenterPartner,
    deleteRowCourse: methods.deleteRowCourse,
    addRowActivity: methods.addRowActivity,
    addRow: methods.addRow,
    deleteRowPartner: methods.deleteRowPartner,
    deleteRowAction: methods.deleteRowAction,
    deleteRowActionActivity: methods.deleteRowActionActivity,
    addRowCenterPartner: methods.addRowCenterPartner,
    deleteCenterPartner: methods.deleteCenterPartner,
    addRowForecast: methods.addRowForecast,
    deleteRowActionForecast: methods.deleteRowActionForecast,
    addRowProfessoratePartner: methods.addRowProfessoratePartner,
    deleteRowProfessoratePartner: methods.deleteRowProfessoratePartner,
    addRowAchievement: methods.addRowAchievement,
    deleteRowTargetAchievement: methods.deleteRowTargetAchievement,
    addRowResultAchievement: methods.addRowResultAchievement,
    deleteRowResultAchievement: methods.deleteRowResultAchievement,
    // validateEndDate: methods.validateEndDate,
    loadSelectOptionsCenterCoordinators(){
      const apiEndpoint = `/api/v1/centers`;
      api.get({url:apiEndpoint})
      .then(response => {
          this.selectOptionsCenterCoordinators = response?.data?.data;
      })
      .catch(error => {
          console.error("Error al cargar la lista:", error);
      });
    },
    loadSelectOptionsStrategicLines(){
      const apiEndpoint = `/api/v1/strategic-lines`;
      api.get({url:apiEndpoint})
      .then(response => {
        this.selectOptionsStrategicLines = response.data.data.map(line => ({
          id: line.id,
          description: line.description
        }));
      })
      .catch(error => {
          console.error("Error al cargar la lista:", error);
      });
    },
    loadCourses(){
      const apiEndpoint = `/api/v1/centers/${this.essay?.essay_coordinator?.center_id}`;
      // const apiEndpoint = `/api/v1/courses?center_id=${this.essay?.essay_coordinator?.center_id}`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.selectedCourses = response?.data?.data?.courses;
          console.log(this.selectedCourses);
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    loadCoursesForCenter(center) {
      const apiEndpoint = `/api/v1/centers/${center.center_id}`;
      api.get({ url: apiEndpoint })
        .then(response => {
          this.$set(this.coursesByCenter, center.center_id, response?.data?.data?.courses || []);
          // this.selectedCoursesCenterPartners = response?.data?.data?.courses || [];
        })
        .catch(error => {
          console.error("Error al cargar los cursos del centro colaborador:", error);
        });
    },
    toggleCollapse(index) {
      // console.log(this.collapseOpen, index);
      this.$set(this.collapseOpen, index, !this.collapseOpen[index]);
    },
    deleteRowTarget(key){      
      if (this.essay?.targets?.length <= 1) {
        Swal.fire({
          title: "No se puede borrar",
          text: "Debe haber al menos un objetivo.",
          icon: "warning",
          confirmButtonColor: "#34c38f",
          confirmButtonText: "Aceptar"
        });
        return;
      }
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no podrá recuperarlo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sí, borrar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          this.essay.targets.splice(key, 1);
        }
      });
    },
    deleteRowTargetResult(index, key){
      if (this.essay?.targets[index]?.target_results?.length <= 1) {
        Swal.fire({
          title: "No se puede borrar",
          text: "Debe haber al menos un resultado.",
          icon: "warning",
          confirmButtonColor: "#34c38f",
          confirmButtonText: "Aceptar"
        });
        return;
      }
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no podrá recuperarlo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sí, borrar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          this.essay.targets[index].target_results.splice(key, 1);
        }
      });
    },
    handleCloseStart() {
      this.isClosing = true;
    },
    handleModalClose() {
      console.log("Cerrando modal");
      this.$v.$reset();
      this.isClosing = false;
      this.$emit('close');
    },
    finishStep(){
      this.draftAction = false;
      console.log("finishStep");
      if (!this.handleValidate()) {
        this.warningMsgValidation();
        return;
      }
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Una vez finalizado no podrá editarlo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sí, finalizar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          this.essay.finished = true;
          this.ending = true;
          this.progress = 0;
          this.fakeProgress();
          this.saveCurrentStep(3);
        }
      });
    },
    handleNextStep(currentTabIndex, nextTabIndex) {
      console.log("handleNextStep");
      
      if (!this.isClosing) {
        this.saveCurrentStep(nextTabIndex);
      }
    },
    warningMsgValidation() {
      Swal.fire({
        icon: 'error',
        html: "Hay campos requeridos sin completar",
        timer: 2000,
        showConfirmButton: false,
      });
    },
    saveEssay(){      
      this.essay.finished = false;
      if (this.essay?.id) {
        this.updateEssay();
      }else{
        this.createEssay();
      }
      this.handleModalClose();
    },
    saveCurrentStep(stepNumber) {
      console.log("Guardado");
      
      // this.$v.$reset();
      if(this.draftAction) return this.saveEssay();
      this.essay.current_step = stepNumber
      this.essay.finished = this.essay.current_step === 3 ? true : false;
      if (stepNumber == 3) {
        this.essay.essay_status_id = 2;
      }
      
      if (this.essay?.id) {
        this.updateEssay();
      }else{
        this.createEssay();
      }
    },
    onFilePartnerCompanyChange(event, partner){
      this.file = event.target.files[0];
      partner.document_name = this.file.name;
      this.uploadFilePartnerCompany(this.file)
      .then((response) => {
        const file = response?.data?.data;
        partner.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    onFileCoordinatorChange(event) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      this.essay.essay_coordinator.document_name = this.file.name;

      this.uploadFileCoordinator(this.file)
      .then((response) => {
        const file = response?.data?.data;
        this.essay.essay_coordinator.document_path = file.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    onFilePartnerChange(event, center) {
      this.file = event.target.files[0];
      center.document_name = this.file.name;
      this.uploadFilePartner(this.file)
      .then((response) => {
        const file = response?.data?.data;
        center.document_path = file.path;
        console.log(center);
      })
      .catch((error) => {
        console.error("Error al crear el centro:", error);
      });
    },
    uploadFileCoordinator(file){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", 'coordinators');
      const config = { headers: { "content-type": "multipart/form-data" } };

      // this.essay.essay_coordinator.document_name = file.name;

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    uploadFilePartner(file){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", 'partners');
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    uploadFilePartnerCompany(file){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", 'companies');
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    createEssay() {
      this.loading = true;
      const apiEndpoint = `/api/v1/essays`;
      this.essay.project_id = this.project;
      this.essay.essay_status_id = 1;
      api.post({url:apiEndpoint, data:this.essay})
        //axios.post(apiEndpoint, this.user)
        .then((response) => {
          this.loading = false;
          const newEssay = response?.data?.data;
          if(!newEssay.essay_coordinator){
            newEssay.essay_coordinator = {
              center_id: null,
              document_name: '',
              document_path: '',
              essay_professorates: []
            }
          }
          this.$emit("updatedEssay", newEssay);
          // this.$emit("success", newEssay);
          // this.$bvModal.hide("ModalEssay");
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al crear el essay:", error);
        });
    },
    updateEssay() {
      this.loading = true;
      const apiEndpoint = `/api/v1/essays`;
      api.put({ url: apiEndpoint, data: this.essay, id: this.essay.id })
        .then((response) => {
          this.progress = 100;
          clearInterval(this.interval);
          this.loading = false;
          this.ending = false;
          const updatedEssay = response?.data?.data;
          if(!updatedEssay.essay_coordinator){
            updatedEssay.essay_coordinator = {
              center_id: null,
              document_name: '',
              document_path: '',
              essay_professorates: []
            }
          }
          console.log(updatedEssay);
          this.$emit("updatedEssay", updatedEssay);

          if (this.essay.finished === true) {
            this.handleModalClose();
            this.successmsg(updatedEssay.url);
          }
        })
        .catch((error) => {
          this.progress = 100; // También completa si hay error
          clearInterval(this.interval);
          this.loading = false;
          this.ending = false;
          console.error("Error al guardar el essay:", error);
        });
    },
    submitForm() {
      console.log(this.essay.current_step);
      // if (this.essay?.id) {
      //   return this.updateEssay();
      // }
      // return this.createEssay();
    },
    loadTargetResults(){
        this.targets.forEach(target => {
          const apiEndpoint = `/api/v1/target-results?target_id=${target.id}`;
          api.get({url:apiEndpoint})
          .then(response => {
            this.targetResults.push(...response?.data?.data);
            // console.log("Array2: ", this.targetResults);
          })
          .catch(error => {
            console.error("Error loading data:", error);
          });
        });
    },
    loadTargets(){
      const apiEndpoint = `/api/v1/targets?essay_id=${this.essay?.id}`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.targets = response?.data?.data;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    loadSelectOptionsCenterPartners(){
      const apiEndpoint = `/api/v1/centers`;
      api.get({url:apiEndpoint})
      .then(response => {
        this.selectOptionsCenterPartners = response?.data?.data;
        // console.log(this.selectOptionsCenterPartners); 
      })
      .catch(error => {
          console.error("Error al cargar la lista:", error);
      });
    },
    loadData(){
      console.log(this.essay.essay_center_partner);
      this.loadTargets();
      this.loadTargetResults();
      this.loadSelectOptionsCenterPartners();
      this.loadSelectOptionsStrategicLines();
      this.loadCourses();
      this.essay.essay_center_partner.forEach((center) => {
        this.loadCoursesForCenter(center);
      });
    },
    successmsg(url) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: "!Buen trabajo!",
        html: `
          <div style="position: relative;">
            <p>Tu proyecto ha sido registrado correctamente. En breve recibirá una respuesta<br><br></p>
            <iframe src="${url}" frameborder="0" style="height: 70vh; width: 100%;"></iframe>
          </div>
        `,
        icon: "success",
        width: 1000,
        padding: 20,
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonText: "Aceptar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.close();
        }
      });
    },
    
    downloadFileAnexo(num, params){
      const queryString = qs.stringify(params);
      const baseUrl = process.env.VUE_APP_API_HOST;
      const url = `${baseUrl}/anexo${num}?${queryString}`;      
      window.open(url, '_blank');
    },

    downloadFileAnexoIV(essay_center_partner){
      const params = {
        'center_id': essay_center_partner?.center_id,
        'essay_id': this.essay?.id,
        'essay_professorates': essay_center_partner?.essay_professorates,
      };
      console.log(params);

      this.downloadFileAnexo(4, params);
    },
    downloadFileAnexoII(name, responsable, cif){
      const params = {
        'partner_name': name,
        'partner_responsable': responsable,
        'partner_cif': cif,
        'essay_id': this.essay?.id,
        'center_id': this.essay?.essay_coordinator?.center_id
      };
      console.log(params);

      this.downloadFileAnexo(2, params);
    },
    downloadFileAnexoI(){
      const params = {
        'center_id': this.essay?.essay_coordinator?.center_id,
        'essay_id': this.essay?.id,
        'essay_professorates': this.essay?.essay_coordinator?.essay_professorates,
      };
      this.downloadFileAnexo(1, params);
    },
    downloadFileAnexoIII(){
      const params = {
        'center_id': this.essay?.essay_coordinator?.center_id,
        'essay_id': this.essay?.id,
        'essay_center_partner_ids': this.essay?.essay_center_partner?.map(partner => partner.center_id) || [],
      };
      this.downloadFileAnexo(3, params);
    },
    handleValidate(){
      let stepFields = [];
      this.$v.$reset();
      if(this.draftAction) return this.handleValidateSave();
      console.log("Activa siguiente paso");
      
      this.$v.$touch();
      switch (this.essay.current_step) {
        case 0:
          stepFields = ['name', 'description', 'antecedentes', 'arte_status', 'strategic_lines', 'centers_number', 'companies_number', 'professorates_number', 'students_number', 'targets']
          break;
        case 1:
          stepFields = ['essay_coordinator', 'courses', 'essay_center_partner', 'partner_companies']
          break;
        case 2:
          stepFields = ['actions'];
          if (
            this.essay.targets?.some((target, i) =>
              target.target_achievements?.some((achievement, j) => {
                const isInvalid = this.$v.essay.targets.$each[i].target_achievements.$each[j].$invalid;
                if (isInvalid) {
                  console.log(`Error en targets[${i}].target_achievements[${j}]`);
                  return true;
                }
                return false;
              }) ||
              target.target_results?.some((result, j) =>
                result.result_achievements?.some((achievement, k) => {
                  const isInvalid = this.$v.essay.targets.$each[i].target_results.$each[j].result_achievements.$each[k].$invalid;
                  if (isInvalid) {
                    console.log(`Error en targets[${i}].target_results[${j}].result_achievements[${k}]`);
                    return true;
                  }
                  return false;
                })
              )
            )
          ) {
            this.warningMsgValidation();
            return false;
          }
          break;
        default:
          break;
      }
      
      const invalid = stepFields.find( field => this.$v.essay[field]?.$invalid );

      if (invalid) {
        this.warningMsgValidation();
        return false;
      }

      return true;
    },

    handleValidateSave(){
      const resolvePath = (obj, path) => path.split('.').reduce((acc, key) => acc?.[key], obj);

      const validateActions = () => {
        const hasError = this.essay.actions?.some((action, i) => {
          this.$v.essay.actions.$each[i].name.$touch(); // Toca el campo name para activar la validación
          if (this.$v.essay.actions.$each[i].name?.$invalid) {
            console.log(`Error en actions[${i}].name`);
            return true;
          }
          // console.log("Retorna false");
          return false;
        });

        console.log(hasError);
        return hasError;

      };

      let stepFields = [];
      switch (this.essay.current_step) {
        case 0:
          stepFields = ['name']
          break;
        case 1:
          stepFields = ['essay_coordinator.center_id', 'courses', 'essay_center_partner', 'partner_companies']
          break;
        case 2:         
          
          // stepFields = ['actions'];
          if (validateActions()) {
            this.warningMsgValidation();
            return false;
          }
          if (
            this.essay.targets?.some((target, i) =>
              target.target_achievements?.some((achievement, j) => {
                const isInvalid = this.$v.essay.targets.$each[i].target_achievements.$each[j].$invalid;
                if (isInvalid) {
                  console.log(`Error en targets[${i}].target_achievements[${j}]`);
                  return true;
                }
                return false;
              }) ||
              target.target_results?.some((result, j) =>
                result.result_achievements?.some((achievement, k) => {
                  const isInvalid = this.$v.essay.targets.$each[i].target_results.$each[j].result_achievements.$each[k].$invalid;
                  if (isInvalid) {
                    console.log(`Error en targets[${i}].target_results[${j}].result_achievements[${k}]`);
                    return true;
                  }
                  return false;
                })
              )
            )
          ) {
            this.warningMsgValidation();
            return false;
          }

          break;
        default:
          break;
      }
      stepFields.forEach(field => resolvePath(this.$v.essay, field)?.$touch());
      const invalid = stepFields.some(field => resolvePath(this.$v.essay, field)?.$invalid);      

      if (invalid) {
        this.warningMsgValidation();
        return false;
      }

      return true;
    },

    validateStep2Actions() {
      console.log("Validando acciones en paso 2...");
      let hasError = false;

      this.essay.actions?.forEach((action, i) => {
        const validation = this.$v.essay.actions.$each[i];
        validation.name.$touch();

        Object.keys(validation).forEach(key => {
          if (validation[key]?.$touch) {
            validation[key].$touch();
          }
        });
        if (validation.name.$invalid) {
          console.log(`❌ Error en actions[${i}]: name es inválido.`);
          hasError = true;
        } else {
          console.log(`✅ actions[${i}].name es válido.`);
        }
      });

      console.log("validaciones de acciones", this.$v.essay.actions.$invalid);
      // Si hay error, mostrar alerta y prevenir el cambio de paso
      if (hasError || this.$v.essay.actions.$invalid) {
        this.warningMsgValidation();
        return false;
      }
      
      
      // Si no hay error, permitir el cambio de paso
      console.log("✅ Paso 2 validado correctamente.");
      return true;
    },
    fakeProgress() {
      // Simula un progreso constante
      this.interval = setInterval(() => {
        if (this.progress < 95) {
          this.progress += 5;
        }
      }, 500);
    },
    saveMsg() {
      Swal.fire({
        icon: 'success',
        html: "Se ha guardado correctamente",
        timer: 2000,
        showConfirmButton: false,
      });
    },
    saveFinishStep() {
      this.draftAction = true;
      if(this.handleValidate()){
        this.saveCurrentStep(3);
        this.saveMsg();
      }
    }
  },
  validations: {
    essay: {
      name: {required},
      description: {required},
      antecedentes: {required},
      arte_status: {required},
      strategic_lines: {required},
      centers_number: {required, numeric},
      companies_number: {required, numeric},
      professorates_number: {required, numeric},
      students_number: {required, numeric},
      courses: {
        $each: {
          id: {required}
        }
      },
      targets: {
          $each: {
            title: {required},
            description: {required},
            target_results: {
              $each: {
                title: {required},
                description: {required},
                result_achievements: {
                  $each: {
                    name: {required},
                    description: {required},
                    measured_values: {required}
                  }
                }
              },
            },
            target_achievements: {
              $each: {
                name: {required},
                description: {required},
                measured_values: {required }
              }
            }
          }
      },
      actions:{
        $each:{
          name: {required},
          descriptions: {required},
          init_date: {required, validDateFormat},
          end_date: { required, validDateFormat, validEndDate },
          responsables: {required, onlyLetters},
          risks: {required},
          contingency_plan: {required},
          economic_forecats: {
            $each: {
              material: {required},
              cost: {
                required,
                noInvalidCharacters,
                validDecimal,
                noCommasOrApostrophes
              }
            }
          },
          education_activities: {
            $each: {
              name: {required},
              methodology: {required}
            }    
          }
        }
      },
      essay_coordinator: {
        center_id: {required},
        document_name: {required},
        essay_professorates: {
          $each: {
            name: {required, onlyLetters},
            department: {required},
            dni: {required},
            email: {required, email}
          } 
        }
      },
      partner_companies: {
        $each: {
          name: {required},
          cif: {required},
          address: {required},
          responsable: {required, onlyLetters},
          email: {required, email},
          document_path: {required}
        }
      },
      essay_center_partner: {
        $each: {
          center_id: {required},
          document_path: {required},
          essay_professorates: {
            $each: {
              name: {required, onlyLetters},
              department: {required},
              dni: {required},
              email: {required, email}
            } 
          }
        }
      }
    }
  }
};
</script>
<template>
  <b-modal :id="id" :title="modalTitle" @shown="loadData" @hide="handleCloseStart" @hidden="handleModalClose" scrollable size="extra-xl" hide-footer no-close-on-backdrop no-close-on-esc>
    <template #default="{ }">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <form-wizard color="#5664d2" :start-index="currentStep" next-button-text="Guardar y continuar"
                back-button-text="Volver" @on-change="handleNextStep">
                <tab-content :before-change="handleValidate" title="Información General y Objetivos">
                  <div class="tab-pane" id="basic-info">
                    <h4 class="card-title">Información</h4>
                    <p class="card-title-desc">Usuario: {{ userName }}</p>
                    <p class="card-title-desc">Fecha de creación: {{ creationDate }}</p>
                    <!-- <form @submit.prevent="submitForm"> -->
                      <div class="form-group">
                        <label for="essayname">Titulo <span style="color: red;">*</span></label>
                        <input v-model="essay.name" id="essayname" name="essayname" type="text" class="form-control" maxlength="200" :class="{ 'is-invalid': $v.essay.name.$error }" />
                        <small class="text-muted">{{ essay?.name?.length }}/200</small>
                        <div v-if="$v.essay.name.$error" class="invalid-feedback">
                          <div v-if="!$v.essay.name.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="productdesc">Descripcion <span style="color: red;">*</span></label><i v-b-tooltip.hover title="Breve descripción del proyecto, 5 líneas" class="far fa-question-circle font-size-12 ml-2"></i>
                        <textarea v-model="essay.description" class="form-control" id="productdesc" rows="3" maxlength="5000" :class="{ 'is-invalid': $v.essay.description.$error }"></textarea>
                        <small class="text-muted">{{ essay?.description?.length }}/5000</small>
                        <div v-if="$v.essay.description.$error" class="invalid-feedback">
                          <div v-if="!$v.essay.description.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="antecedentes">Antecendentes <span style="color: red;">*</span></label><i v-b-tooltip.hover title="Describe diagnóstico inicial y situaciones/realidades que se quieren mejorar " class="far fa-question-circle font-size-12 ml-2"></i>
                        <textarea v-model="essay.antecedentes" class="form-control" id="antecedentes" rows="3" maxlength="5000" :class="{ 'is-invalid': $v.essay.antecedentes.$error }"></textarea>
                        <small class="text-muted">{{ essay?.antecedentes?.length }}/5000</small>
                        <div v-if="$v.essay.antecedentes.$error" class="invalid-feedback">
                          <div v-if="!$v.essay.antecedentes.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="arte_status">Estado del arte <span style="color: red;">*</span></label><i v-b-tooltip.hover title="Indica brevemente que avances y desarrollos existen actualmente en campos similares al contenido de vuestro proyecto" class="far fa-question-circle font-size-12 ml-2"></i>
                        <textarea v-model="essay.arte_status" class="form-control" id="arte_status" rows="3" maxlength="5000" :class="{ 'is-invalid': $v.essay.arte_status.$error }"></textarea>
                        <small class="text-muted">{{ essay?.arte_status?.length }}/5000</small>
                        <div v-if="$v.essay.arte_status.$error" class="invalid-feedback">
                          <div v-if="!$v.essay.arte_status.required">{{ errorMessages.required }}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label for="manufacturername">Nº profesorado participante <span style="color: red;">*</span></label>
                            <input v-model="essay.professorates_number" id="manufacturername" name="manufacturername" type="text" class="form-control" :class="{ 'is-invalid': $v.essay.professorates_number.$error }" />
                            <div v-if="$v.essay.professorates_number.$error" class="invalid-feedback">
                              <div v-if="!$v.essay.professorates_number.numeric">El campo debe ser numérico.</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label for="manufacturername">Nº alumnado involucrado <span style="color: red;">*</span></label>
                            <input v-model="essay.students_number" id="manufacturername" name="manufacturername" type="text" class="form-control"  :class="{ 'is-invalid': $v.essay.students_number.$error }" />
                            <div v-if="$v.essay.students_number.$error" class="invalid-feedback">
                              <div v-if="!$v.essay.students_number.numeric">El campo debe ser numérico.</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label for="manufacturerbrand">Nº centros <span style="color: red;">*</span></label>
                            <input v-model="essay.centers_number" id="manufacturerbrand" name="manufacturerbrand" type="text" class="form-control"  :class="{ 'is-invalid': $v.essay.centers_number.$error }"/>
                            <div v-if="$v.essay.centers_number.$error" class="invalid-feedback">
                              <div v-if="!$v.essay.centers_number.numeric">El campo debe ser numérico.</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label for="price">Nº empresas <span style="color: red;">*</span></label>
                            <input v-model="essay.companies_number" id="price" name="price" type="text" class="form-control"  :class="{ 'is-invalid': $v.essay.companies_number.$error }"/>
                            <div v-if="$v.essay.companies_number.$error" class="invalid-feedback">
                              <div v-if="!$v.essay.companies_number.numeric">El campo debe ser numérico.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="productdesc">Objetivos generales del proyecto <span style="color: red;">*</span></label><i v-b-tooltip.hover title="Seleccionar del desplegable los objetivos generales descritos en la plataforma que se pretenden alcanzar con el proyecto" class="far fa-question-circle font-size-12 ml-2"></i>
                        <multiselect 
                          v-model="essay.strategic_lines"
                          :options="selectOptionsStrategicLines"
                          :multiple="true"
                          label="description"
                          track-by="id"
                          placeholder="Selecciona estrategia"
                          :class="{ 'is-invalid': $v.essay.strategic_lines.$error }"
                        ></multiselect>
                      </div>
                      <h4 class="card-title">Objetivos <span style="color: red;">*</span></h4>
                      <b-card no-body class="mb-1 shadow-none" v-for="(target, i) in essay.targets" :key="i" :set="v = $v.essay.targets.$each[i]">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <h6 class="m-0 d-flex justify-content-between align-items-center">
                            <div class="form-inline">
                              <span>Objetivo: {{ target.title }}</span>
                            </div>
                            <div>
                              <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle="'collapse-' + i"></b-icon>
                              <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Eliminar"
                                @click="deleteRowTarget(i)">
                                <i class="mdi mdi-trash-can font-size-18"></i>
                              </a>
                            </div>
                          </h6>
                        </b-card-header>
                        <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                          <b-card-body>
                            <b-card-text>
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label for="title">Título <span style="color: red;">*</span></label>
                                  <input type="text" v-model="target.title" class="form-control" id="title" placeholder="Título" maxlength="200" :class="{ 'is-invalid': v.title.$error }"/>
                                  <!-- Recuento de caracteres -->
                                  <small class="text-muted">{{ target?.title?.length }}/200</small>
                                  <div v-if="v.title.$error" class="invalid-feedback">
                                    <div v-if="!v.title.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label for="descriptionTarget">Descripción <span style="color: red;">*</span></label>
                                  <textarea v-model="target.description" class="form-control" id="descriptionTarget" rows="3" maxlength="5000" placeholder="Descripción" :class="{ 'is-invalid': v.description.$error }"></textarea>
                                  <small class="text-muted">{{ target?.description?.length }}/5000</small>
                                  <div v-if="v.description.$error" class="invalid-feedback">
                                    <div v-if="!v.description.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                              <label>Resultados</label>
                              <b-card no-body class="mb-1 shadow-none" v-for="(target_result, j) in target.target_results" :key="'target-result-' + j" :set="vresult = v.target_results.$each[j]">
                              <!-- <b-card no-body class="mb-1 shadow-none" v-for="(target_result, j) in target.target_results" :key="'target-result-' + j"> -->
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                  <h6 class="m-0 d-flex justify-content-between align-items-center">
                                    <div class="form-inline">
                                      <span>Resultado: {{ target_result.title }}</span>
                                    </div>
                                    <div>
                                      <b-icon style="margin-right: 10px" :icon="collapseOpen['target-result-collapse-' + j] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('target-result-collapse-' + j)"  v-b-toggle="'target-result-collapse-' + j"></b-icon>
                                      <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Eliminar"
                                        @click="deleteRowTargetResult(i, j)">
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                      </a>
                                    </div>
                                  </h6>
                                </b-card-header>
                                <b-collapse :id="'target-result-collapse-' + j" accordion="variation-accordion-target-result" role="tabpanel">
                                  <b-card-body>
                                    <b-card-text>
                                      <div class="col-lg-12">
                                        <div class="form-group">
                                          <label for="title">Título <span style="color: red;">*</span></label>
                                          <input type="text" v-model="target_result.title" class="form-control" id="title" placeholder="Título" maxlength="200" :class="{ 'is-invalid': vresult?.title?.$error }" />
                                          <small class="text-muted">{{ target_result?.title?.length }}/200</small>
                                          <div v-if="vresult?.title?.$error" class="invalid-feedback">
                                            <div v-if="!vresult?.title?.required">{{ errorMessages.required }}</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-lg-12">
                                        <div class="form-group">
                                          <label for="descriptionTargetResult">Descripción <span style="color: red;">*</span></label>
                                          <textarea v-model="target_result.description" class="form-control" id="descriptionTargetResult" rows="3" maxlength="5000" placeholder="Descripción" :class="{ 'is-invalid': vresult.description.$error }"></textarea>
                                          <small class="text-muted">{{ target_result?.description?.length }}/5000</small>
                                          <div v-if="vresult.description.$error" class="invalid-feedback">
                                            <div v-if="!vresult.description.required">{{ errorMessages.required }}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </b-card-text>
                                  </b-card-body>
                                </b-collapse>
                              </b-card>
                              <a href="javascript:void(0);" class="text-success" @click="addRowResults(target)">
                                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar nuevo resultado
                              </a>
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                      <a href="javascript:void(0);" class="text-success" @click="addRow()">
                        <i class="mdi mdi-plus-circle font-size-18"></i> Agregar nuevo objetivo
                      </a>
                    <!-- </form> -->
                  </div>
                </tab-content>
                <tab-content :before-change="handleValidate" title="Centros y Empresas">
                  <div class="tab-pane" id="product-img">
                    <h4 class="card-title">Centro Coordinador</h4>
                    <div class="form-group col-12 mt-4">
                      <div class="row">
                        <div class="form-group col-12">
                          <label for="selectedCenterCoordinator">Centro coordinador <span style="color: red;">*</span></label>
                          <select class="form-control" id="selectedCenterCoordinator" v-model="essay.essay_coordinator.center_id" @change="loadCourses" :class="{ 'is-invalid': $v.essay.essay_coordinator.center_id.$error }">
                            <option :value="null">Selecciona centro</option>
                            <option v-for="option in selectOptionsCenterCoordinators" :value="option.id" :key="option.id">{{ option.name }}</option>
                          </select>
                        </div>
                      </div>
                      <label for="file">Profesorado participante</label>
                      <table class="table table-bordered table-sticky-header">
                        <thead>
                          <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Departamento</th>
                            <th scope="col">DNI</th>
                            <th scope="col">Email</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(professorate, i) in essay?.essay_coordinator?.essay_professorates" :key="i" :set="vcoordinator = $v.essay.essay_coordinator.essay_professorates.$each[i]" class="">
                            <td>
                              <div class="form-group">
                                <input type="text" v-model="professorate.name" class="form-control" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vcoordinator.name.$error }"/>
                                <small class="text-muted">{{ professorate?.name?.length }}/200</small>
                                <div v-if="vcoordinator.name.$error" class="invalid-feedback">
                                  <div v-if="!vcoordinator.name.required">{{ errorMessages.required }}</div>
                                  <div v-else-if="!vcoordinator.name.onlyLetters">{{ errorMessages.onlyLetters }}</div>
                                </div>                                   
                              </div>
                            </td>
                            <td>
                              <div class="form-group">
                                <input type="text" v-model="professorate.department" class="form-control" placeholder="Departamento" maxlength="200" :class="{ 'is-invalid': vcoordinator.department.$error }"/>                                     
                                <small class="text-muted">{{ professorate?.department?.length }}/200</small>
                                <div v-if="vcoordinator.department.$error" class="invalid-feedback">
                                  <div v-if="!vcoordinator.department.required">{{ errorMessages.required }}</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group">
                                <input type="text" v-model="professorate.dni" class="form-control" placeholder="DNI" maxlength="10" :class="{ 'is-invalid': vcoordinator.dni.$error }"/>                                          
                                <small class="text-muted">{{ professorate?.dni?.length }}/10</small>
                                <div v-if="vcoordinator.dni.$error" class="invalid-feedback">
                                  <div v-if="!vcoordinator.dni.required">{{ errorMessages.required }}</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group">
                                <input type="text" v-model="professorate.email" class="form-control" placeholder="Email" :class="{ 'is-invalid': vcoordinator.email.$error }"/>                                          
                                <div v-if="vcoordinator.email.$error" class="invalid-feedback">
                                  <div v-if="!vcoordinator.email.required">{{ errorMessages.required }}</div>
                                  <div v-else-if="!vcoordinator.email.email">{{ errorMessages.email }}</div>
                                </div>
                              </div>
                            </td>
                            <td class="text-center">
                                <a
                                    href="javascript:void(0);"
                                    class="text-danger"
                                    v-b-tooltip.hover
                                    title="Eliminar"
                                    @click="deleteRowProfessorate(i)"
                                >
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="4" class="text-right">
                                <a href="javascript:void(0);" class="text-success" @click="addRowProfessorate()">
                                  <i class="mdi mdi-plus-circle font-size-18"></i> Agregar profesorado
                                </a>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <b-alert show variant="warning">
                        Observaciones<br>
                        <ul>
                          <li>Para poder generar el <strong>Anexo I</strong> con los datos introducidos, primero debes seleccionar centro coordinador y añadir profesorado participante.</li>
                          <li>En caso de no añadir profesorado participante, estos no estarán reflejados en el <strong>Anexo I</strong></li>
                        </ul>
                      </b-alert>
                      <div class="form-inline mt-5">
                        <div class="form-group col-2">
                          <label for="file">Descargar Anexo I:</label>
                          <button id="btn-anexoI" @click.prevent="downloadFileAnexoI()" class="btn btn-info mr-4" :disabled="!essay.essay_coordinator.center_id">
                            <i class="fas fa-file-download mr-2"></i> Anexo I
                          </button>
                        </div>
                        <div class="form-group col-10">
                          <label for="file">Documento de aprobación del claustro y consejo escolar (Anexo I):  <span style="color: red;">*</span></label>
                          <div class="custom-file">
                            <input id="inputFile" type="file" ref="fileInput" name="file" class="custom-file-input" @change="onFileCoordinatorChange" :class="{ 'is-invalid': $v.essay.essay_coordinator.document_name.$error }" data-browse="Selecciona"/>
                            <label class="custom-file-label" for="inputFile">
                              {{ essay.essay_coordinator.document_name || 'Seleccione un archivo' }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-12 mt-5">
                      <label for="course_ids">Ciclos formativos relacionados <span style="color: red;">*</span></label>
                      <table class="table table-bordered table-sticky-header">
                        <thead>
                          <tr>
                            <th scope="col">Ciclo</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(course, i) in essay.courses" :key="i" class="" :set="vcourse = $v.essay.courses.$each[i]">
                            <td>
                              <div class="form-group">
                                <select class="form-control" id="course_ids" v-model="course.id" :class="{ 'is-invalid': vcourse.id.$error }">
                                  <option value="">Selecciona ciclo</option>
                                  <option v-for="option in selectedCourses" :value="option.id" :key="option.id">{{ option.mode_code }} - {{ option.education }} {{ option.mode }}</option>
                                </select>
                                <div v-if="vcourse.id.$error" class="invalid-feedback">
                                  <div v-if="!vcourse.id.required">{{ errorMessages.required }}</div>
                                </div>                                  
                              </div>
                            </td>
                            <td class="text-center">
                                <a
                                    href="javascript:void(0);"
                                    class="text-danger"
                                    v-b-tooltip.hover
                                    title="Eliminar"
                                    @click="deleteRowCourse(i)"
                                >
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="4" class="text-right">
                                <a href="javascript:void(0);" class="text-success" @click="addRowCourse()">
                                  <i class="mdi mdi-plus-circle font-size-18"></i> Agregar ciclo
                                </a>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <hr style="border-top: 5px solid rgb(0 0 0 / 10%)">
                    <h4 class="card-title mt-4">Centro colaborador</h4>
                    <b-card no-body class="mb-4 shadow-none mt-4" v-for="(center, i) in essay.essay_center_partner" :key="i" :set="vpartner = $v.essay.essay_center_partner.$each[i]">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <h6 class="m-0 d-flex justify-content-between align-items-center">
                          <div class="form-inline">
                            <span>Centro colaborador {{ selectOptionsCenterPartners.find(item => item.id == center.center_id)?.name }}</span>
                          </div>
                          <div>
                            <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle="'collapse-' + i"></b-icon>
                            <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Eliminar"
                              @click="deleteCenterPartner(i)">
                              <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </div>
                        </h6>
                      </b-card-header>
                      <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label for="selectOptionsCenterPartners">Centro colaborador <span style="color: red;">*</span></label>
                                  <select class="form-control" v-model="center.center_id" @change="loadCoursesForCenter(center)" :class="{ 'is-invalid': vpartner.center_id.$error }">
                                    <option value="">Selecciona centro</option>
                                    <option v-for="option in selectOptionsCenterPartners" :value="option.id" :key="option.id">{{ option.name }}</option>
                                  </select> 
                                  <div div v-if="vpartner.center_id.$error" class="invalid-feedback">
                                   <div v-if="!vpartner.center_id.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <label for="file">Profesorado participante</label>
                            <table class="table table-bordered table-sticky-header">
                              <thead>
                                <tr>
                                  <th scope="col">Nombre</th>
                                  <th scope="col">Departamento</th>
                                  <th scope="col">DNI</th>
                                  <th scope="col">Email</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(professorate, j) in center.essay_professorates" :key="j" class="" :set="vprofessorate = vpartner.essay_professorates.$each[j]">
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="professorate.name" class="form-control" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vprofessorate.name.$error }"/>
                                      <div div v-if="vprofessorate.name.$error" class="invalid-feedback">
                                        <div v-if="!vprofessorate.name.required">{{ errorMessages.required }}</div>
                                        <div v-else-if="!vprofessorate.name.onlyLetters">{{ errorMessages.onlyLetters }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="professorate.department" class="form-control" placeholder="Departamento" maxlength="200" :class="{ 'is-invalid': vprofessorate.department.$error }"/>
                                      <div div v-if="vprofessorate.department.$error" class="invalid-feedback">
                                        <div v-if="!vprofessorate.department.required">{{ errorMessages.required }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="professorate.dni" class="form-control" placeholder="DNI" maxlength="10" :class="{ 'is-invalid': vprofessorate.dni.$error }"/>
                                      <div div v-if="vprofessorate.dni.$error" class="invalid-feedback">
                                        <div v-if="!vprofessorate.dni.required">{{ errorMessages.required }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="professorate.email" class="form-control" placeholder="Email" :class="{ 'is-invalid': vprofessorate.email.$error }"/>
                                      <div div v-if="vprofessorate.email.$error" class="invalid-feedback">
                                        <div v-if="!vprofessorate.email.required">{{ errorMessages.required }}</div>
                                        <div v-else-if="!vprofessorate.email.email">{{ errorMessages.email }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowProfessoratePartner(center, j)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="8" class="text-right">
                                    <a href="javascript:void(0);" class="text-success" @click="addRowProfessoratePartner(center)">
                                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar profesorado
                                    </a>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            <b-alert show variant="warning">
                              Observaciones<br>
                              <ul>
                                <li>Para poder generar el <strong>Anexo IV</strong> con los datos introducidos, primero debes seleccionar centro coordinador y añadir profesorado participante.</li>
                                <li>En caso de no añadir profesorado participante, estos no estarán reflejados en el <strong>Anexo IV</strong></li>
                              </ul>
                            </b-alert>
                            <div class="form-inline mt-5">
                              <div class="form-group col-2">
                                <label for="file">Descargar Anexo IV:</label>
                                <button id="btn-anexoI" @click.prevent="downloadFileAnexoIV(center)" :disabled="!center.center_id" class="btn btn-info mr-4">
                                  <i class="fas fa-file-download mr-2"></i> Anexo IV
                                </button>
                              </div>
                              <div class="form-group col-10">
                                <label for="filePartner">Documento de aprobación del claustro y consejo escolar (Anexo IV): <span style="color: red;">*</span></label>
                                <div class="custom-file">
                                  <input id="inputFilePartner" type="file" name="filePartner" class="custom-file-input" @change="event => onFilePartnerChange(event, center)" :class="{ 'is-invalid': vpartner.document_path.$error }" />
                                  <label class="custom-file-label" for="inputFilePartner">{{ center?.document_name }}</label>
                                  <div v-if="vpartner.document_path.$error" class="invalid-feedback">
                                    <div v-if="!vpartner.document_path.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-12 mt-5">
                            <label for="course_ids">Ciclos formativos relacionados <span style="color: red;">*</span></label>
                            <table class="table table-bordered table-sticky-header">
                              <thead>
                                <tr>
                                  <th scope="col">Ciclo</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(course, k) in center.courses" :key="k" class="" >
                                  <td>
                                    <div class="form-group">
                                      <select class="form-control" id="course_ids" v-model="course.id">
                                        <option value="">Selecciona ciclo</option>
                                        <option v-for="option in coursesByCenter[center.center_id] || []" :value="option.id" :key="option.id">{{ option.mode_code }} - {{ option.education }} {{ option.mode }}</option>
                                      </select>                                
                                    </div>
                                  </td>
                                  <td class="text-center">
                                      <a
                                          href="javascript:void(0);"
                                          class="text-danger"
                                          v-b-tooltip.hover
                                          title="Eliminar"
                                          @click="deleteRowCourseCenterPartner(center, k)"
                                      >
                                          <i class="mdi mdi-trash-can font-size-18"></i>
                                      </a>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="4" class="text-right">
                                      <a href="javascript:void(0);" class="text-success" @click="addRowCoursesPartner(center)">
                                        <i class="mdi mdi-plus-circle font-size-18"></i> Agregar ciclo
                                      </a>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <a href="javascript:void(0);" class="text-success" @click="addRowCenterPartner()">
                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar centro
                    </a>
                    <hr style="border-top: 5px solid rgb(0 0 0 / 10%)">
                    <h4 class="card-title mt-4">Empresas / Organismos colaboradores</h4>
                    <b-card no-body class="mb-4 shadow-none mt-4" v-for="(partner, i) in essay?.partner_companies" :key="'partner-'+i" :set="vcompany = $v.essay.partner_companies.$each[i]" >
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <h6 class="m-0 d-flex justify-content-between align-items-center">
                          <div class="form-inline">
                            <!-- <span>Empresa colaboradora {{ selectOptionsCenterPartners.find(item => item.id == center.center_id)?.name }}</span> -->
                            <span>Empresa colaboradora </span>
                          </div>
                          <div>
                            <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('partner-collapse-' + i)"  v-b-toggle="'partner-collapse-' + i"></b-icon>
                            <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Eliminar"
                              @click="deleteRowPartner(i)">
                              <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </div>
                        </h6>
                      </b-card-header>
                      <b-collapse :id="'partner-collapse-' + i" accordion="variation-accordion-partner" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-lg-4">
                                <div class="form-group">
                                  <label for="name">Nombre <span style="color: red;">*</span></label>
                                  <input type="text" v-model="partner.name" class="form-control" id="name" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vcompany.name.$error }" />
                                  <div v-if="vcompany.name.$error" class="invalid-feedback">
                                    <div v-if="!vcompany.name.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="form-group">
                                  <label for="partnerCIF">CIF <span style="color: red;">*</span></label>
                                  <input type="text" v-model="partner.cif" class="form-control" id="partnerCIF" placeholder="CIF" maxlength="10" :class="{ 'is-invalid': vcompany.cif.$error }" />
                                  <div v-if="vcompany.cif.$error" class="invalid-feedback">
                                    <div v-if="!vcompany.cif.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="form-group">
                                  <label for="partnerResponsable">Responsable <span style="color: red;">*</span></label>
                                  <input type="text" v-model="partner.responsable" class="form-control" id="partnerResponsable" maxlength="200" placeholder="Responsable" :class="{ 'is-invalid': vcompany.responsable.$error }" />
                                  <div v-if="vcompany.responsable.$error" class="invalid-feedback">
                                    <div v-if="!vcompany.responsable.required">{{ errorMessages.required }}</div>
                                    <div v-else-if="!vcompany.responsable.onlyLetters">{{ errorMessages.onlyLetters }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="partnerEmail">Email <span style="color: red;">*</span></label>
                              <input v-model="partner.email" class="form-control" id="partnerEmail" placeholder="Email" :class="{ 'is-invalid': vcompany.email.$error }" />
                              <div v-if="vcompany.email.$error" class="invalid-feedback">
                                <div v-if="!vcompany.email.required">{{ errorMessages.required }}</div>
                                <div v-else-if="!vcompany.email.email">{{ errorMessages.email }}</div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="partnerAddress">Dirección <span style="color: red;">*</span></label>
                              <input v-model="partner.address" class="form-control" id="partnerAddress" placeholder="Dirección" maxlength="500" :class="{ 'is-invalid': vcompany.address.$error }" />
                              <div v-if="vcompany.address.$error" class="invalid-feedback">
                                <div v-if="!vcompany.address.required">{{ errorMessages.required }}</div>
                              </div>
                            </div>
                            <b-alert show variant="warning">
                              Observaciones<br>
                              <ul>
                                <li>Para poder generar el <strong>Anexo II</strong> de empresas u organismos colaboradores, debes introducir sus datos en las siguientes tablas.</li>
                                <li>En caso de no añadir empresas u organismos colaboradores, estos no estarán reflejados en el <strong>Anexo II</strong></li>
                              </ul>
                            </b-alert>
                            <div class="form-inline mt-5">
                              <div class="form-group col-2">
                                <label for="file">Generar Anexo II:</label>
                                <button id="btn-anexoI" @click.prevent="downloadFileAnexoII(partner.name, partner.responsable, partner.cif)" class="btn btn-info mr-4">
                                  <i class="fas fa-file-download mr-2"></i> Anexo II
                                </button>
                              </div>
                              <div class="form-group col-10">
                                <label for="filePartnerCompany">Carta de compromiso de participación de la empresa o entidad colaboradora (Anexo II): <span style="color: red;">*</span></label>
                                <div class="custom-file">
                                  <input id="inputFilePartnerCompany" type="file" name="fileCompany" class="custom-file-input" @change="event => onFilePartnerCompanyChange(event, partner)" :class="{ 'is-invalid': vcompany.document_path.$error }"/>
                                  <label class="custom-file-label" for="inputFilePartnerCompany">{{ partner?.document_name }}</label>
                                  <div v-if="vcompany.document_path.$error" class="invalid-feedback">
                                    <div v-if="!vcompany.document_path.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <a href="javascript:void(0);" class="text-success" @click="addRowPartner()">
                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar empresa / organismo
                    </a>
                    <div class="form-group col-12">
                      <!-- <table class="table table-bordered table-sticky-header">
                        <thead>
                          <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">CIF</th>
                            <th scope="col">Dirección</th>
                            <th scope="col">Responsable</th>
                            <th scope="col">Email</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(partner, i) in essay?.partner_companies">
                            <tr :key="'data-'+i" class="">
                              <td>
                                <div class="form-group">
                                  <input type="text" v-model="partner.name" class="form-control" placeholder="Nombre" />                                          
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <input type="text" v-model="partner.cif" class="form-control" placeholder="CIF" />                                     
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <input type="text" v-model="partner.address" class="form-control" placeholder="Dirección" />                                          
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <input type="text" v-model="partner.responsable" class="form-control" placeholder="Responsable" />                                          
                                </div>
                              </td>
                              <td>
                                <div class="form-group">
                                  <input type="text" v-model="partner.email" class="form-control" placeholder="Email" />                                          
                                </div>
                              </td>
                              <td class="text-center" rowspan="2">
                                  <a
                                      href="javascript:void(0);"
                                      class="text-danger"
                                      v-b-tooltip.hover
                                      title="Eliminar"
                                      @click="deleteRowPartner(i)"
                                  >
                                      <i class="mdi mdi-trash-can font-size-18"></i>
                                  </a>
                              </td>
                            </tr>
                            <tr :key="'file-'+i" class="">
                              <td colspan="2">
                                <tr>
                                  <label for="file">Descargar Anexo II: </label>
                                </tr>
                                <tr>
                                  <button id="btn-anexoI" @click.prevent="downloadFileAnexoII(partner.name, partner.responsable, partner.cif)" class="btn btn-info mr-4">
                                    <i class="fas fa-file-download mr-2"></i> Anexo II
                                  </button>
                                </tr>
                              </td>
                              <td colspan="3">
                                <tr><label for="file">Documento: </label></tr>
                                <tr>
                                  <div class="form-group">
                                    <div class="custom-file">
                                      <input id="inputFilePartnerCompany" type="file" name="fileCompany" class="custom-file-input" @change="event => onFilePartnerCompanyChange(event, partner)" />
                                      <label class="custom-file-label" for="inputFilePartnerCompany">{{ partner?.fileName }}</label>
                                    </div>
                                  </div>
                                </tr>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="6" class="text-right">
                                <a href="javascript:void(0);" class="text-success" @click="addRowPartner()">
                                  <i class="mdi mdi-plus-circle font-size-18"></i> Agregar empresa / organismo
                                </a>
                            </td>
                          </tr>
                        </tfoot>
                      </table> -->
                    </div>
                  </div>
                </tab-content>
                <tab-content title="Acciones e Indicadores de realización">
                  <div class="tab-pane" id="metadata">
                    <h4 class="card-title">Planificación de acciones</h4>
                    <b-card no-body class="mb-1 shadow-none mt-4" v-for="(action, i) in essay.actions" :key="i" :set="vaction = $v.essay.actions.$each[i]">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <h6 class="m-0 d-flex justify-content-between align-items-center">
                          <div class="form-inline">
                            <span>Acción: {{ action.name }}</span>
                          </div>
                          <div>
                            <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle= "'collapse-' + i"></b-icon>
                            <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Eliminar"
                              @click="deleteRowAction(i)">
                              <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>
                          </div>
                        </h6>
                      </b-card-header>
                      <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-lg-4">
                                <div class="form-group">
                                  <label for="name">Nombre <span style="color: red;">*</span></label>
                                  <input type="text" v-model="action.name" class="form-control" id="name" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vaction.name.$error }" />
                                  <small class="text-muted">{{ action?.name?.length }}/200</small>
                                  <div v-if="$v.essay.actions.$each[i].name.$error" class="invalid-feedback">
                                    <div v-if="!$v.essay.actions.$each[i].name.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="form-group">
                                  <label for="initDate">Ejecución inicial <span style="color: red;">*</span></label>
                                  <!-- <input type="date" :value="formatDate(action.init_date)" @input="updateDate($event, i, 'init_date')" id="initDate" class="form-control" placeholder="Ejecución inicial" :class="{ 'is-invalid': vaction.init_date.$error }"/> -->
                                  <input type="date" v-model="action.init_date" id="initDate" class="form-control" placeholder="Ejecución inicial" :class="{ 'is-invalid': vaction.init_date.$error }"/>
                                  <div v-if="vaction.init_date.$error" class="invalid-feedback">
                                    <div v-if="!vaction.init_date.required">Este campo es obligatorio o incorrecto</div>
                                    <div v-else-if="!vaction.init_date.validDateFormat">Introduce una fecha valida</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4">
                                <div class="form-group">
                                  <label for="endDate">Ejecución final <span style="color: red;">*</span></label>
                                  <!-- <input type="date" :value="formatDate(action.end_date)" @input="updateDate($event, i, 'end_date'); validateEndDate(i)" id="endDate" class="form-control" placeholder="Ejecución final" :class="{ 'is-invalid': vaction.end_date.$error }"/> -->
                                  <input type="date" v-model="action.end_date" id="endDate" class="form-control" placeholder="Ejecución final" :class="{ 'is-invalid': vaction.end_date.$error }"/>
                                  <div v-if="vaction.end_date.$error" class="invalid-feedback">
                                    <div v-if="!vaction.end_date.required">Este campo es obligatorio o incorrecto</div>
                                    <div v-else-if="!vaction.end_date.validDateFormat">Introduce una fecha valida</div>
                                    <div v-else-if="!vaction.end_date.validEndDate">
                                      La ejecución final no puede ser menor que la inicial
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="descriptionAction">Descripción <span style="color: red;">*</span></label><i v-b-tooltip.hover title="Describe en que consiste la acción, que centros, departamentos y empresas van a participar" class="far fa-question-circle font-size-12 ml-2"></i>
                              <textarea v-model="action.descriptions" class="form-control" id="descriptionAction" rows="3" maxlength="5000" placeholder="Descripción" :class="{ 'is-invalid': vaction.descriptions.$error }"></textarea>
                              <small class="text-muted">{{ action?.descriptions?.length }}/5000</small>
                              <div v-if="vaction.name.$error" class="invalid-feedback">
                                <div v-if="!vaction.name.required">{{ errorMessages.required }}</div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label for="actionResponsable">Responsable <span style="color: red;">*</span></label><i v-b-tooltip.hover title="Nombre de la persona o personas responsables de coordinar y gestionar la acción" class="far fa-question-circle font-size-12 ml-2"></i>
                                  <input type="text" v-model="action.responsables" id="actionResponsable" class="form-control" maxlength="200" placeholder="Responsable" :class="{ 'is-invalid': vaction.responsables.$error }"/>
                                  <div v-if="vaction.responsables.$error" class="invalid-feedback">
                                    <div v-if="!vaction.responsables.required">{{ errorMessages.required }}</div>
                                    <div v-else-if="!vaction.responsables.onlyLetters">{{ errorMessages.onlyLetters }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label for="actionRisks">Riesgos <span style="color: red;">*</span></label><i v-b-tooltip.hover title="En caso de no existir riesgos reseñables, indicar 'La acción se podrá desarrollar'. en caso de posibles riesgos, indicarlos y describirlos" class="far fa-question-circle font-size-12 ml-2"></i>
                                  <textarea v-model="action.risks" class="form-control" id="actionRisks" rows="3" maxlength="5000" placeholder="Riesgos" :class="{ 'is-invalid': vaction.risks.$error }"></textarea>
                                  <small class="text-muted">{{ action?.risks?.length }}/5000</small>
                                  <div v-if="vaction.risks.$error" class="invalid-feedback">
                                    <div v-if="!vaction.risks.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <label for="actionPlan">Plan de contingencia <span style="color: red;">*</span></label><i v-b-tooltip.hover title="Cuando existan riesgos, definir el plan a aplicar. En caso contrario, indicar 'No aplica'" class="far fa-question-circle font-size-12 ml-2"></i>
                                  <textarea v-model="action.contingency_plan" class="form-control" id="actionPlan" rows="3" maxlength="5000" placeholder="Plan de contingencia" :class="{ 'is-invalid': vaction.contingency_plan.$error }"></textarea>
                                  <small class="text-muted">{{ action?.contingency_plan?.length }}/5000</small>
                                  <div v-if="vaction.contingency_plan.$error" class="invalid-feedback">
                                    <div v-if="!vaction.contingency_plan.required">{{ errorMessages.required }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <label for="file">Actividades educativas</label>
                            <table class="table table-bordered table-sticky-header">
                              <thead>
                                <tr>
                                  <th scope="col">Nombre <i v-b-tooltip.hover title="Título de la actividad a realizar" class="far fa-question-circle font-size-12 ml-2"></i></th>
                                  <th scope="col">Metodología <i v-b-tooltip.hover title="Breve descripción de la actividad y que metodología didáctica se usará para llevarla a cabo" class="far fa-question-circle font-size-12 ml-2"></i></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(activity, j) in action.education_activities" :key="j" class="" :set="vactivity = vaction.education_activities.$each[j]">
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="activity.name" class="form-control" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vactivity.name.$error }" />
                                      <small class="text-muted">{{ activity?.name?.length }}/200</small>
                                      <div v-if="vactivity.name.$error" class="invalid-feedback">
                                        <div v-if="!vactivity.name.required">{{ errorMessages.required }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="activity.methodology" class="form-control" placeholder="Metodología" maxlength="200" :class="{ 'is-invalid': vactivity.methodology.$error }" />
                                      <small class="text-muted">{{ activity?.methodology?.length }}/200</small>
                                      <div v-if="vactivity.methodology.$error" class="invalid-feedback">
                                        <div v-if="!vactivity.methodology.required">{{ errorMessages.required }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowActionActivity(i, j)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="8" class="text-right">
                                    <a href="javascript:void(0);" class="text-success" @click="addRowActivity(action)">
                                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar actividad
                                    </a>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            <label for="file">Previsión económica</label>
                            <table class="table table-bordered table-sticky-header">
                              <thead>
                                <tr>
                                  <th scope="col">Material</th>
                                  <th scope="col">Coste (€)</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(forecast, k) in action.economic_forecats" :key="k" class="" :set="vforecast = vaction.economic_forecats.$each[k]">
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="forecast.material" class="form-control" placeholder="Material" maxlength="200" :class="{ 'is-invalid': vforecast.material.$error }"/>
                                      <small class="text-muted">{{ forecast?.material?.length }}/200</small>
                                      <div v-if="vforecast.material.$error" class="invalid-feedback">
                                        <div v-if="!vforecast.material.required">{{ errorMessages.required }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="forecast.cost" class="form-control" placeholder="Coste (€)" maxlength="20" :class="{ 'is-invalid': vforecast.cost.$invalid && vforecast.cost.$dirty, }"/>
                                      <div v-if="vforecast.cost.$dirty" class="invalid-feedback">
                                        <span v-if="!vforecast.cost.required">
                                          Este campo es requerido
                                        </span>
                                        <span v-if="!vforecast.cost.noCommasOrApostrophes">
                                          El separador de decimales debe ser el punto (.).
                                        </span>
                                        <span v-else-if="!vforecast.cost.noInvalidCharacters">
                                          Solo se permiten valores numéricos.
                                        </span> 
                                        <span v-else-if="!vforecast.cost.validDecimal">
                                          Introduce un número válido.
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowActionForecast(i, k)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="8" class="text-right">
                                    <a href="javascript:void(0);" class="text-success" @click="addRowForecast(action)">
                                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar previsión
                                    </a>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <a href="javascript:void(0);" class="text-success" @click="addRowAction()">
                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar acción
                    </a>
                    <h4 class="card-title mt-5">Indicador de realización de objetivos</h4>
                    <b-card no-body class="mb-1 shadow-none mt-4" v-for="(target, i) in essay.targets" :key="'target-' + i">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <h6 class="m-0 d-flex justify-content-between align-items-center">
                          <div class="form-inline">
                            <span>Objetivo: {{ target.title }}</span>
                          </div>
                          <div>
                            <b-icon style="margin-right: 10px" :icon="collapseOpen[i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('target-collapse-' + i)"  v-b-toggle="'target-collapse-' + i"></b-icon>
                          </div>
                        </h6>
                      </b-card-header>
                      <b-collapse :id="'target-collapse-' + i" accordion="variation-accordion" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                            <label for="file">Indicador de realización de objetivos</label>
                            <table class="table table-bordered table-sticky-header">
                              <thead>
                                <tr>
                                  <th scope="col">Nombre</th>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">Valores de medida</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(achievement, j) in target.target_achievements" :key="'archievement-' + j" class="" :set="vindicador = $v.essay.targets.$each[i].target_achievements.$each[j]">
                                  <td>
                                    <div class="form-group">
                                      <input type="text" v-model="achievement.name" class="form-control" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vindicador.name.$error }"/>
                                      <small class="text-muted">{{ achievement?.name?.length }}/200</small>
                                      <div v-if="vindicador.name.$error" class="invalid-feedback">
                                        <div v-if="!vindicador.name.required">{{ errorMessages.required }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="form-group">
                                      <textarea v-model="achievement.description" class="form-control" placeholder="Descripción" rows="4" maxlength="5000" :class="{ 'is-invalid': vindicador.description.$error }"></textarea>                                     
                                      <small class="text-muted">{{ achievement?.description?.length }}/5000</small>
                                      <div v-if="vindicador.description.$error" class="invalid-feedback">
                                        <div v-if="!vindicador.description.required">{{ errorMessages.required }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="form-group">
                                      <textarea v-model="achievement.measured_values" class="form-control" placeholder="Valores de medida" rows="4" maxlength="5000" :class="{ 'is-invalid': vindicador.measured_values.$error }"></textarea>
                                      <small class="text-muted">{{ achievement?.measured_values?.length }}/5000</small>
                                      <div v-if="vindicador.measured_values.$error" class="invalid-feedback">
                                        <div v-if="!vindicador.measured_values.required">{{ errorMessages.required }}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowTargetAchievement(i, j)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="8" class="text-right">
                                    <a href="javascript:void(0);" class="text-success" @click="addRowAchievement(target)">
                                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar indicador
                                    </a>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </b-card-text>
                          <b-card-text>
                            <label for="file">Indicador de realización de resultados</label>
                            <b-card no-body class="mb-1 shadow-none mt-4" v-for="(result, k) in target.target_results" :key="'result-' + k" :set="vr = $v.essay.targets.$each[i].target_results.$each[k]">
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <h6 class="m-0 d-flex justify-content-between align-items-center">
                                  <div class="form-inline">
                                    <span>Resultado: {{ result.title }}</span>
                                  </div>
                                  <div>
                                    <b-icon style="margin-right: 10px" :icon="collapseOpen['result-collapse-' + k] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('result-collapse-' + k)"  v-b-toggle="'result-collapse-' + k"></b-icon>
                                  </div>
                                </h6>
                              </b-card-header>
                              <b-collapse :id="'result-collapse-' + k" accordion="variation-accordion-result" role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <table class="table table-bordered table-sticky-header">
                                      <thead>
                                        <tr>
                                          <th scope="col">Nombre</th>
                                          <th scope="col">Descripción</th>
                                          <th scope="col">Valores de medida</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(result_achievement, l) in result.result_achievements" :key="'result-archievement-' + l" class="" :set="vrindicador = vr.result_achievements.$each[l]">
                                          <td>
                                            <div class="form-group">
                                              <input type="text" v-model="result_achievement.name" class="form-control" placeholder="Nombre" maxlength="200" :class="{ 'is-invalid': vrindicador.name.$error }"/>
                                              <small class="text-muted">{{ result_achievement?.name?.length }}/200</small>
                                              <div v-if="vrindicador.name.$error" class="invalid-feedback">
                                                <div v-if="!vrindicador.name.required">{{ errorMessages.required }}</div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="form-group">
                                              <textarea v-model="result_achievement.description" class="form-control" placeholder="Descripción" rows="4" maxlength="5000" :class="{ 'is-invalid': vrindicador.description.$error }"></textarea>
                                              <small class="text-muted">{{ result_achievement?.description?.length }}/5000</small>
                                              <div v-if="vrindicador.description.$error" class="invalid-feedback">  
                                                <div v-if="!vrindicador.description.required">{{ errorMessages.required }}</div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="form-group">
                                              <textarea v-model="result_achievement.measured_values" class="form-control" placeholder="Valores de medida" rows="4" maxlength="5000" :class="{ 'is-invalid': vrindicador.measured_values.$error }"></textarea>
                                              <small class="text-muted">{{ result_achievement?.measured_values?.length }}/5000</small>
                                              <div v-if="vrindicador.measured_values.$error" class="invalid-feedback">  
                                                <div v-if="!vrindicador.measured_values.required">{{ errorMessages.required }}</div>
                                              </div>
                                            </div>
                                          </td>
                                          <td class="text-center">
                                            <a
                                                href="javascript:void(0);"
                                                class="text-danger"
                                                v-b-tooltip.hover
                                                title="Eliminar"
                                                @click="deleteRowResultAchievement(i, k, l)"
                                            >
                                                <i class="mdi mdi-trash-can font-size-18"></i>
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colspan="8" class="text-right">
                                            <a href="javascript:void(0);" class="text-success" @click="addRowResultAchievement(result)">
                                              <i class="mdi mdi-plus-circle font-size-18"></i> Agregar indicador
                                            </a>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </tab-content>
                <template slot="next" slot-scope="props">
                  <wizard-button @click.native="draftAction = true" class="wizard-footer-left mr-2" style="color: #fff;background-color: #1cbb8c;border-color: #1cbb8c;">Guardar</wizard-button>
                  <wizard-button @click.native="draftAction = false" class="wizard-footer-right" :style="props.fillButtonStyle">Guardar y continuar</wizard-button>
                </template>
                <template slot="finish" slot-scope="props">
                  <wizard-button @click.native="saveFinishStep" :disabled="ending" class="wizard-footer-left mr-2" style="color: #fff;background-color: #1cbb8c;border-color: #1cbb8c;">Guardar</wizard-button>
                  <wizard-button @click.native="finishStep" :disabled="loading" class="wizard-footer-right finish-button" :style="props.fillButtonStyle"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="ending"></span> {{ending ? 'Finalizando...' : 'Finalizar'}}</wizard-button>
                </template>
              </form-wizard>
              <b-alert v-if="ending" show>
                <b-icon icon="exclamation-circle-fill" class="mr-2" variant="info"></b-icon> Se está generando la memoria del proyecto. Espere un momento...
              </b-alert>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<style>
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #76c7c0;
  transition: width 0.2s;
}
</style>